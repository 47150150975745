import React, { useState } from 'react';
import "./CodeDisplay.module.scss";
import styles from "./CodeDisplay.module.scss";
import cardIcon from "../../assets/cartao.svg";
import { splitInGroupsOfFour } from "../../utils";
//@ts-ignore
import Barcode from "react-barcode";


enum viewModes {
    barCode,
    qrCode
}
interface CustomerInfo {
    userName: string
    qrCodeUri: string
    barCodeUri: string
    pan: string
    expiration: string
}

interface Props {
    customerInfo: CustomerInfo
}

export const CodeDisplay = (props: Props) => {
    const [viewMode, setViewMode] = useState(viewModes.qrCode)

    return (
        <React.Fragment>
            <div className={styles.customerInfoContainer}>
                <div className={styles.toggles}>
                    <div className={`pointer${viewMode === viewModes.barCode ? ' selected' : ''}`} onClick={() => setViewMode(viewModes.barCode)}>BARCODE</div>
                    <div className={`pointer${viewMode === viewModes.qrCode ? ' selected' : ''}`} onClick={() => setViewMode(viewModes.qrCode)}>QR CODE</div>
                </div>
                {viewMode === viewModes.barCode && (
                    <Barcode value={props.customerInfo.pan} fontSize={0} className={styles.barCode}/>
                )}
                {viewMode === viewModes.qrCode && (
                    <img src={props.customerInfo.qrCodeUri} className={styles.qrCode} alt="QR Code" />
                )}
                <div className={styles.cardInfo}>
                    <img src={cardIcon} alt="Personal Account Number" className={styles.cardIcon}/><p className={styles.pan}><span dangerouslySetInnerHTML={{__html: splitInGroupsOfFour(props.customerInfo.pan)}}></span></p>
                </div>
                <p className='invalid'>Válido até {props.customerInfo.expiration}</p>
            </div>
        </React.Fragment>
    )
}