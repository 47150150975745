import React, { PropsWithChildren, ReactNode } from 'react';
import fnacLogo from '../../assets/logo.svg';
import styles from './ViewDescription.module.scss';
import "../../styles/typography.scss";
import CONSTANTS from "../../data/constants.json"
import { Link } from 'react-router-dom';

interface Props {
    children: ReactNode
}

const ViewDescription:React.FC<PropsWithChildren<Props>> = (props) => {
    return (
        <React.Fragment>
            <div className={styles.background}>
                <div className={styles.container}>
                    <header>
                        <div className={styles.logoContainer}>
                            <Link to={CONSTANTS.urlFnac}><img src={fnacLogo} className={styles.logo} alt="Fnac" /></Link>
                        </div>
                    </header>
                    <div className={styles.textContainer}>
                        {props.children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ViewDescription