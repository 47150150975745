import axios from 'axios';

const IOS_BUNDLE = process.env.REACT_APP_API_BUNDLE;
const IOS_PLATFORM = process.env.REACT_APP_API_PLATFORM;

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const requestConfig =  {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

axios.defaults.baseURL = `${API_ENDPOINT}`;

const instance = axios.create();

const createSession = (vatNumber: string, birthdate: string) => {
    const params = new URLSearchParams();

    params.append("nif", vatNumber);
    params.append('birth', birthdate);
    params.append('uuid', 'unique_identifier');
    params.append('bundle', IOS_BUNDLE || "");
    params.append('platform', IOS_PLATFORM || "");

    const request = instance.post("/sessions", params, requestConfig);
    
    return request;
}

const authorizeSession = (otpTicket: string, otpCode: string) => {

    const params = new URLSearchParams();
    params.append("ticket", otpTicket);
    params.append('secret', otpCode);

    const request = instance.post("/sessions/authorize", params, requestConfig);
    
    return request;
}

export { createSession, authorizeSession }
