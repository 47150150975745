import React from 'react';
import "./styles/global.scss";
import "./styles/colors.scss";
import "./styles/typography.scss";
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { Main } from './Routes'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NoCode } from './Routes';
import { NoLogin } from './Routes';

const EXTRAFEATURES = {
  LOCALSTORAGE: false, // Save customer info in localstorage for five consecutive days;
}

function App() {
  return (
    <ErrorBoundary>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route index element={<Main extraFeatures={EXTRAFEATURES} />} />
            <Route path="/no-code" element={<NoCode />} />
            <Route path="/no-login" element={<NoLogin />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
}

export default App;
