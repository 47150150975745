import React, { PropsWithChildren, useState, useEffect } from 'react';
import Button from '../../components/Button/Button';
import { AxiosError } from 'axios';
import { createSession, authorizeSession } from '../../services/api';
import styles from './Main.module.scss';
import './Main.module.scss';
import LayoutDistribution from '../../components/LayoutDistribution/LayoutDistribution';
import { CodeDisplay } from '../../components/CodeDisplay/CodeDisplay';
import { Link } from 'react-router-dom';

interface Props {
    extraFeatures?: {
        LOCALSTORAGE: boolean
    }
}

const KEEPLOCALSTORAGE = parseInt(process.env.REACT_APP_LOCALSTORAGE_KEEP || "604800000");

const Main: React.FC<PropsWithChildren<Props>> = (props: Props) => {
    const [currentStage, setCurrentStage] = useState(0);
    const [vatNumber, setVatNumber] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [otpTicket, setOtpTicket] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [customerInfo, setCustomerInfo] = useState({
        userName: "",
        qrCodeUri: "",
        barCodeUri: "",
        pan: "",
        expiration: "",
    });
    
    useEffect(() => {
        if (props.extraFeatures?.LOCALSTORAGE) {
            const storedInfo = localStorage.getItem('customerInfo')
            
            if (currentStage === 0 && storedInfo !== null) {
                const parsedInfo = JSON.parse(storedInfo)
                const { expiration, infoExpiration } = parsedInfo;
                const cardExpiration = new Date(expiration)
                const dataExpiration = new Date(infoExpiration)
                const currentDate = new Date()
                
                if (cardExpiration > currentDate && dataExpiration > currentDate) {
                    setCustomerInfo(parsedInfo)
                    setCurrentStage(2)
                }
            }
        }
    }, [currentStage, props.extraFeatures?.LOCALSTORAGE])
    
    const [apiStatus, setApiStatus] = useState({
        operationAccepted: true,
        statusCode: 200,
        errorMessage: "",
    });

    const submitForm = (currentStage: number, firstValue: string, secondValue: string) => {
        switch (currentStage) {
            case 0:
                setIsLoading(true);
                createSession(firstValue, secondValue).then(response => {
                    setApiStatus({
                        operationAccepted: true,
                        statusCode: 200,
                        errorMessage: ""
                    })
                    setOtpTicket(response.data.data.ticket)
                    setCurrentStage(1);
                    setIsLoading(false);
                }).catch((error: AxiosError) => {
                    setApiStatus({
                        operationAccepted: false,
                        statusCode: error.response?.data.error.http,
                        errorMessage: error.response?.data.error.message
                    })
                    setIsLoading(false);
                    if (error.response?.data.error.http !== 400 && error.response?.data.error.http !== 401) throw new Error("Unhandled Exception")
                });
                break;
            case 1:
                setIsLoading(true);
                authorizeSession(firstValue, secondValue).then(response => {
                    setCustomerInfo({
                        userName: response.data.data.user.name,
                        qrCodeUri: response.data.data.user.public_account_number.qrcode,
                        barCodeUri: response.data.data.user.public_account_number.barcode,
                        pan: response.data.data.user.public_account_number.code,
                        expiration: response.data.data.user.expiration_date
                    })
                    if (props.extraFeatures?.LOCALSTORAGE) {
                        localStorage.setItem('customerInfo', JSON.stringify({
                            userName: response.data.data.user.name,
                            qrCodeUri: response.data.data.user.public_account_number.qrcode,
                            barCodeUri: response.data.data.user.public_account_number.barcode,
                            pan: response.data.data.user.public_account_number.code,
                            expiration: response.data.data.user.expiration_date,
                            infoExpiration: new Date(new Date().getTime()+KEEPLOCALSTORAGE)
                        }))
                    }
                    setCurrentStage(2);
                    setIsLoading(false);
                }).catch((error: AxiosError) => {
                    setApiStatus({
                        operationAccepted: false,
                        statusCode: error.response?.data.error.http,
                        errorMessage: error.response?.data.error.message
                    })
                    setIsLoading(false)
                    if (error.response?.data.error.http !== 400 && error.response?.data.error.http !== 403) throw new Error("Unhandled Exception")
                })
                break;
            default:
                throw new Error("Unexpected form stage (request)")
        }
    }

    const renderDescription = () => {
        if (currentStage === 0 || currentStage === 1) {
            return (
                <>
                    <h2 className={styles.subtitle}>Cartão Parceiros FNAC</h2>
                    <h1 className={styles.title}>Faz login para aceder ao QR Code ou Código de Barras para acesso aos parceiros FNAC.</h1>
                    <p className={styles.body}>Descobre todas as <span className={styles.bodyStrongWhite}>vantagens</span> e descontos exclusivos em <span className={styles.bodyStrongWhite}>parceiros Cartão FNAC</span> em FNAC.PT</p>
                </>
        )} else if (currentStage === 2) {
            return (
                <>
                    <h2 className={styles.subtitle}>Bem vindo(a)</h2>
                    <h1 className={styles.title}>{customerInfo.userName}</h1>
                    <p className={styles.body}>Utiliza o código, o QR Code ou Código de Barras para aceder às vantagens nos parceiros FNAC.</p>
                </>
            )
        } else {
            throw new Error("Unexpected state")
        }
    }

    const renderInteraction = () => {
        return (
            <div>
                {currentStage === 0 && (
                    <>
                        <form onSubmit={(event) => {
                            event?.preventDefault();
                            event?.stopPropagation();
                            submitForm(0, vatNumber, birthDate);
                        }} className={styles.formsContainer}>
                            <div className={styles.form}>
                                <input type="text" className={styles.input} onChange={e => setVatNumber(e.target.value)} id="vatNumber" required/>
                                <label htmlFor="vatNumber" className={styles.inputLabel}>Número de Identificação Fiscal</label>
                            </div>
                            <div className={styles.form}>
                                    <input 
                                        type="date" 
                                        className={styles.input}
                                        onChange={e => setBirthDate(e.target.value)}
                                        id="birthDate" required/>
                                <label htmlFor="birthDate" className={styles.inputLabelDate}>Data de Nascimento</label>
                            </div>
                            <div className={styles.form}>
                                {!apiStatus.operationAccepted && apiStatus.statusCode === 400 && (
                                    <p className={styles.errorMessage}>{apiStatus.errorMessage}</p>
                                )}
                                {!apiStatus.operationAccepted && apiStatus.statusCode === 401 && (
                                    <p className={styles.errorMessage}>NIF ou data de nascimento estão incorretos</p>
                                )}
                                {!apiStatus.operationAccepted && apiStatus.statusCode !== 400 && apiStatus.statusCode !== 401 && (
                                    <p className={styles.errorMessage}>Erro a contactar servidor. Tenta novamente mais tarde.</p>
                                )}
                                <Button label="Validar" isLoading={isLoading} />
                                <Link className={styles.helpButton} to="/no-login" >Não consigo aceder</Link>
                            </div>
                        </form>
                    </>
                )}
                {currentStage === 1 && (
                    <>
                        <form onSubmit={(event) => {
                            event?.stopPropagation();
                            event?.preventDefault();
                            submitForm(1, otpTicket, otpCode);
                        }} className={styles.formsContainer}>
                            <p className={styles.bodyStrongWithMargin}>Introduz o código enviado para o e-mail ou telefone associados ao teu Cartão FNAC.</p>
                            <div className={styles.otpForm}>
                                <input type="number" className={styles.input} onChange={e => setOtpCode(e.target.value)} id="otpCode" required/>
                                <label htmlFor="otpCode" className={styles.inputLabel}>Código</label>
                            </div>
                            <div className={styles.otpForm}>
                                {!apiStatus.operationAccepted && apiStatus.statusCode === 400 && (
                                    <p className={styles.errorMessage}>{apiStatus.errorMessage}</p>
                                )}
                                {!apiStatus.operationAccepted && apiStatus.statusCode === 403 && (
                                    <p className={styles.errorMessage}>O código está incorreto</p>
                                )}
                                {!apiStatus.operationAccepted && apiStatus.statusCode !== 400 && apiStatus.statusCode !== 403 && (
                                    <p className={styles.errorMessage}>Erro a contactar servidor. Tenta novamente mais tarde.</p>
                                )}
                                <Button label="Validar" isLoading={isLoading}><span className={vatNumber === "" || birthDate === "" ? "invalid" : ""}>Validar</span></Button>
                                <Link className={styles.helpButton} to="/no-code" >Não recebi o código</Link>
                            </div>
                        </form>
                    </>
                )}
                {currentStage === 2 && (
                    <CodeDisplay customerInfo={customerInfo} />
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
                <LayoutDistribution header={renderDescription()} isLoading={isLoading} >
                        {renderInteraction()}  
                </LayoutDistribution>
        </React.Fragment>
    )
}

export { Main }