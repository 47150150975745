import React, { PropsWithChildren } from 'react';
import Button from '../../components/Button/Button';
import styles from './NoCode.module.scss';
import './NoCode.module.scss';
import LayoutDistribution from '../../components/LayoutDistribution/LayoutDistribution';
import CONSTANTS from '../../data/constants.json'

interface Props{
}

const NoCode: React.FC<PropsWithChildren<Props>> = () => {

    const renderDescription = () => {
        return (
            <div>
                <h1 className={styles.headerTitle}>Não recebeste o código?</h1>
                <p className={styles.body}>No caso de não teres recebido um SMS, ou um e-mail por favor liga para o nosso Call Center para validar e atualizar os teus dados de aderente.</p>
            </div>
        )
    }

    const renderInteraction = () => {
        return(
            <div className={styles.errorScreen}>
                <div className={styles.errorText}>
                    <div>
                        <h2 className={styles.subtitle}>CALL CENTER FNAC</h2>
                        {window.innerWidth > 992 && <h1 className={styles.phoneNumber}>{CONSTANTS.phoneNumber}</h1>}
                        <p className={styles.regular}>10h — 20h, Segunda a Domingo (chamada para rede fixa nacional)</p>
                    </div>
                </div>
                {window.innerWidth <= 992 && <Button href={`tel:${CONSTANTS.phoneNumber}`} label={CONSTANTS.phoneNumber}/>}
            </div>
        )
    }

    return (
        <React.Fragment>
            <LayoutDistribution header={renderDescription()}>
                    {renderInteraction()}  
            </LayoutDistribution>
        </React.Fragment>
    )
}

export { NoCode }