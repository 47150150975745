import React, { PropsWithChildren, ReactNode } from 'react';
import poweredByLegend from '../../assets/powered_by_7egend.svg';
import styles from './ViewInteraction.module.scss';
import CONSTANTS from '../../data/constants.json';
interface Props {
    children: ReactNode
}

const ViewInteraction:React.FC<PropsWithChildren<Props>> = (props) => {
    return (
        <React.Fragment>
           <div className={styles.container}>
                <div className={styles.contentContainer}>
                    {props.children}
                </div>
                <a href={CONSTANTS.urlLegend} className={styles.legendAnchor}><img src={poweredByLegend} className={styles.legend} alt="Powered by 7egend" /></a>
           </div>
        </React.Fragment>
    )
}

export default ViewInteraction