import React, { PropsWithChildren, ReactNode } from 'react';
// import CookieBar from '../CookieBar/CookieBar';
import ViewDescription from '../ViewDescription/ViewDescription';
import ViewInteraction from '../ViewInteraction/ViewInteraction';

interface Props {
    header: JSX.Element
    children:  ReactNode
    isLoading?: boolean
}

const LayoutDistribution:React.FC<PropsWithChildren<Props>> = (props) => {
    return (
        <div className='layout row row-cols-1 row-cols-lg-2' style={{margin: 'auto', height: '100%'}}>
            <ViewDescription>
                {props.header}  
            </ViewDescription>
            <ViewInteraction>
                {props.children}
            </ViewInteraction>
            {/* <CookieBar /> */}
        </div>
    )   
}

export default LayoutDistribution