import React, { ErrorInfo } from 'react';
import fnacLogo from '../../assets/logo.svg'
import styles from './ErrorBoundary.module.scss';
import CONSTANTS from '../../data/constants.json'

interface Props{
    children: JSX.Element
}

interface State{
    error: Error | null
    errorInfo: ErrorInfo | null
}

class ErrorBoundary extends React.Component<Props, State> {
    state = { error: null, errorInfo: null };
    
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }
    
    render() {
        if (this.state.errorInfo) {
        // Error path
        return (
                <div className={styles.container}>
                    <header>
                        <img src={fnacLogo} alt="Fnac" />
                    </header>
                    <div className={styles.errorMessage}>
                        <h1 className={styles.errorTitle}>Algo correu mal</h1>
                        <p>Ocorreu um erro inesperado. Por favor tente novamente ou contacte o nosso apoio ao cliente.</p>
                    </div>
                    <div className={styles.contactInfo}>
                        <div className={styles.contactInfoContent}>
                            <h2 className='subtitle'>CALL CENTER FNAC</h2>
                            {window.innerWidth > 992 && (
                                <h2>{CONSTANTS.phoneNumber}</h2>
                            )}
                            <p>10h — 20h, Segunda a Domingo (chamada para rede fixa nacional)</p>
                            {window.innerWidth <= 992 && (
                                <a type="button" className={styles.button} href={`tel:${CONSTANTS.phoneNumber}`}><span className={styles.buttonLabel}>{CONSTANTS.phoneNumber}</span></a>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }  
}

export default ErrorBoundary