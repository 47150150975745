import React from 'react';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import styles from './Button.module.scss';


interface Props{
    isLoading?: boolean
    submitAction?: () => void
    label?: string
    href?: string
    isValid?: boolean
}

const Button: React.FC<Props> = (props: Props): JSX.Element => {
    if (!props.href) {
        return (
            <>
                <button className={styles.button} type="submit"><span className={`whiteText ${<span className={!props.isValid ? "invalid" : ""}>{props.label}</span>}`}>{props.isLoading ? <LoadingIndicator /> : props.label}</span></button>
            </>
        )
    } else if (!!props.href) {
        return (
            <a type="button" className={styles.button} href={props.href}><span className={styles.buttonLabel}>{props.label}</span></a>
        )
    } else {
        return (
            <button className={styles.button}>None</button>
        )
    }
}

export default Button