import React, { PropsWithChildren } from 'react';
import Button from '../../components/Button/Button';
import styles from './NoLogin.module.scss';
import './NoLogin.module.scss';
import LayoutDistribution from '../../components/LayoutDistribution/LayoutDistribution';
import fnacCard from '../../assets/cartao_fnac.svg';
import calendar from '../../assets/calendar.svg';
import CONSTANTS from '../../data/constants.json'

interface Props {
}

const NoLogin: React.FC<PropsWithChildren<Props>> = () => {

    const renderDescription = () => {
        return (
            <div className={styles.mobileContainer}>
                <h1 className={styles.headerTitle}>Não consegues fazer login?</h1>
                <p className={styles.bodyTextTitle}>Ok, vamos tentar resolver isto. Por favor confirma que tentaste os seguintes passos:</p>
                <div className={styles.infoContainer}>
                    <div className={styles.iconsContainer}>
                        <img src={fnacCard} className={styles.iconFnac} alt="Cartão Fnac" />
                        <img src={calendar} className={styles.iconCalendar} alt="Calendário" />
                    </div>
                    <div className={styles.textContainer}>
                        <p className={styles.bodyTextFnac}>Confirmar se os dados inseridos correspondem aos que estão associados ao Cartão FNAC</p>
                        <p className={styles.bodyTextCalendar}>O teu cartão está dentro da validade</p>
                    </div>
                </div>
            </div>
        )
    }

    const renderInteraction = () => {
        return(
            <div className={styles.errorScreen}>
                <div className={styles.errorText}>
                    <div>
                        <p className={styles.bodyTextStrong}>Já confirmei tudo e continuo sem conseguir entrar com os meus dados.</p>
                        <p className={styles.bodyText}>Nesse caso, o melhor é contactares a nossa linha de apoio ao cliente para tentarmos resolver da melhor maneira.</p>
                    </div>
                    <div>
                        <h2 className={styles.subtitle}>CALL CENTER FNAC</h2>
                        {window.innerWidth > 992 && <p className={styles.phoneNumber}>{CONSTANTS.phoneNumber}</p>}
                        <p className={styles.bodyText}>10h — 20h, Segunda a Domingo (chamada para rede fixa nacional)</p>
                    </div>
                </div>
                {window.innerWidth <= 992 && <Button href={`tel:${CONSTANTS.phoneNumber}`} label={CONSTANTS.phoneNumber}/>}
            </div>
        )
    }

    return (
        <React.Fragment>
            <LayoutDistribution header={renderDescription()} >
                <div className={styles.interactionContainer}>
                    {renderInteraction()}  
                </div> 
            </LayoutDistribution>
        </React.Fragment>
    )
}

export { NoLogin }